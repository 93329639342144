import React from "react";
import { Link, navigate } from "gatsby";
import styled from "styled-components";

const Tile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
  border: 1px solid #333;
  flex: 1;
  max-width: 300px;
  min-width: 250px;
  min-height: 200px;
  cursor: pointer;
  background-image: ${props => (props.bg ? `url(${props.bg})` : null)};
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  transition: box-shadow 0.5s ease-out;
  &:hover {
    box-shadow: 0 0 15px #000;
  }
  /* Excerpt */
  p {
    background: white;
    opacity: 0.9;
    width: 80%;
    margin: 10px auto;
    padding: 1rem;
    font-style: italic;
  }
  /* Title/Date */
  div {
    background: white;
    width: calc(100% - 1rem);
    min-height: 2.5rem;
    border-radius: 0 0 10px 10px;
    padding: 0.5rem;
    opacity: 0.9;
    align-self: end;
    span {
      display: block;
      font-size: 0.8rem;
      color: #333;
    }
  }
`;

const OverviewTile = ({ node }) => {
  const { slug, frontmatter, excerpt, timeToRead } = node;
  const { embeddedImages, publishedAt, title, categories } = frontmatter;
  const publicURL = embeddedImages && embeddedImages[0].publicURL;
  console.log(excerpt);
  return (
    <Tile
      key={slug}
      bg={publicURL}
      onClick={() => navigate(`/article/${slug}`)}
    >
      {categories.includes("Instagram") ? <b></b> : <p>{excerpt} →</p>}
      <div>
        <Link to={`/article/${slug}`}>{title}</Link>
        <span> {publishedAt}</span>
      </div>
    </Tile>
  );
};

export default OverviewTile;
