import { graphql } from "gatsby";
import React, { useContext } from "react";
import styled from "styled-components";

import Seo from "../components/seo";
import { ContentBox, ContentBg } from "../components/ui";

// import { getDoc, doc, setDoc } from "firebase/firestore";
import { FirebaseContext } from "../components/Firebase";
import OverviewTile from "../components/blog/overview-tile";

const TileList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-left: 0;
`;

const BlogPage = ({ data }) => (
  <ContentBg>
    <ContentBox fullWidth>
      <Seo title="Blog" />
      <h1>Blog</h1>
      <TileList>
        {data.allMdx.nodes.map(node => (
          <OverviewTile node={node} key={node.slug} />
        ))}
      </TileList>
    </ContentBox>
  </ContentBg>
);

export const query = graphql`
  query {
    allMdx(
      sort: { fields: frontmatter___publishedAt, order: DESC } # filter: { frontmatter: { publishedAt: { lt: "2001", gt: "1999" } } }
    ) {
      totalCount
      nodes {
        frontmatter {
          publishedAt(formatString: "MMMM D, YYYY")
          title
          embeddedImages {
            publicURL
          }
          categories
        }
        slug
        excerpt
      }
      pageInfo {
        currentPage
        hasPreviousPage
      }
    }
  }
`;

export default BlogPage;
